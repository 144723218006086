<template>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane :label="item.name" :name="item.code" :key="index" v-for="(item,index) in pondList">
            <CluePage :pond-id="item.id" v-if="activeName === item.code"></CluePage>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import CluePage from "@/views/clue/component/CluePage.vue";
import * as clueApi from '@/api/clue/cluePond'

export default {
    components: {CluePage},
    name: "AllClue",
    data() {
        return {
            activeName: "",
            pondList: [],
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        findPondList() {
            clueApi.findCluePond({}).then(res => {
                if (res.success) {
                    this.pondList = res.data
                    if (this.pondList) {
                        this.activeName = this.pondList[0].code
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    },
    mounted() {
        this.findPondList()
    }
}
</script>

<style scoped>

</style>